@import "../../../Functions";

.Section-2 {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: rem-calc(25px);
  padding: rem-calc(20px, 30px);
  z-index: 5;

  .Section-left {
    max-width: 50%;

    .Content {
      font-size: rem-calc(25px);
      font-weight: lighter;
      letter-spacing: 1px;
    }

    .Socials {
      display: flex;
      align-items: center;
      gap: rem-calc(5px);
      margin-top: rem-calc(20px);

      .Button {
        margin-right: rem-calc(10px);
      }

      .Icon {
        border-radius: rem-calc(5px);
        padding: rem-calc(10px);
        height: rem-calc(45px);
        width: rem-calc(45px);

        &:hover {
          cursor: pointer;
          background: var(--light_grey);
        }
      }
    }
  }

  .Section-right {
    img {
      max-height: rem-calc(650px);
      border-radius: rem-calc(75px, 75px, 75px, 5px);
      box-shadow: 5px 5px 3px var(--grey);
    }
  }
}

@keyframes pan {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
}

@media screen and (max-width: 850px) {
  .Section-2 {
    flex-direction: column;

    .Section-left {
      max-width: 100%;
    }

    .Section-right {
      img {
        max-height: rem-calc(500px);
        width: 100%;
        object-fit: cover;
        object-position: top;
      }
    }
  }
}