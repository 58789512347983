@import "../Functions";

.Navbar {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    background: var(--white);
    width: 100%;
    height: rem-calc(75px);
    padding: rem-calc(0px, 25px);
    z-index: 25;

    @include create-spikes-bottom-down(var(--white));

    .Navbar-title {
        display: flex;
        gap: rem-calc(20px);
        font-size: rem-calc(48px);
        font-weight: bold;
        letter-spacing: 1px;
        text-decoration: none;

        .Firstname {
            color: var(--primary_color);
            text-shadow: 2px 2px var(--black);
        }
        .Lastname {
            color: var(--white);
            text-shadow: 2px 2px var(--black);
        }
    }
    .Navbar-menuIcon {
        z-index: 5;
        height: rem-calc(50px);
        width: rem-calc(50px);

        path {
            stroke: var(--primary_color);
        }
    }
}

.Menu-overlay {
    display: flex;
    align-items: center;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    top: -100%;
    right: 0;
    position: fixed;
    z-index: 20;
    opacity: 0;
    transition: opacity 0.5s ease, top 1s ease;

    &.Show {
        opacity: 1;
        top: 0
    }
    video{
        min-width: 100%;
        min-height: 100vh;
        object-fit: cover;
        filter: brightness(20%);
    }
    .Content {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-size: rem-calc(160px);
        font-weight: bold;
        color: var(--white);
        text-shadow: 5px 5px var(--primary_color);

        & > div {
            text-align: center;
        }
    }
}

@media screen and (max-width: 850px) {
    .Navbar {
        .Navbar-title {
            font-size: rem-calc(32px);
        }
    }

    .Menu-overlay {
        .Content {
            font-size: rem-calc(120px);
        }
    }
}